import { array, object, string } from 'idonttrustlikethat';

const indicators_list = object({
  indicatorName: string,
  indicatorValue: string
  });
const indicatorsList = array(indicators_list);

type Indicatorlist = typeof indicators_list.T;
type Indicatorslist = typeof indicatorsList.T;

export { indicators_list, indicatorsList };
export type { Indicatorlist, Indicatorslist };