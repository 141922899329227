export const generateRedirectionUrl = ( programSlug: string, projectSlug?: string, subProjectSlug?: string): string => {
        
    let baseUrl = process.env.REACT_APP_FRONT_URL + "/onedatateam/" + programSlug
    if (projectSlug) {
        baseUrl += "/" + projectSlug;
    }
    if (subProjectSlug) {
        baseUrl += "/" + subProjectSlug;
    }
    return baseUrl;
}