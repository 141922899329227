import { boolean, literal, number, object, string } from 'idonttrustlikethat';
import { videoLinks } from '../videos/video';

const videoODTComponent = literal('blocks.videos-odt');
const videoODTBlock = object({
  id: number,
  __component: videoODTComponent,
  title: string.nullable(),
  video_links: videoLinks,
  buttonName: string.nullable(),
  buttonUrl: string.nullable(),
  openNewTab: boolean.nullable()
});

type VideoODTBlock = typeof videoODTBlock.T;
type VideoODTComponent = typeof videoODTComponent.T;

export { videoODTBlock, videoODTComponent };
export type { VideoODTBlock, VideoODTComponent };
