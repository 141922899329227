
import { FC } from 'react';
import './tool-odt-content.scss';
import { getStrapiImage } from '../../../../utils/image';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { ToolDetail } from '../../../../types/strapi/component/tool-odt/tool-odt';
import { NJButton } from '@engie-group/fluid-design-system-react';
interface ToolOdtContentProps {
  tool: ToolDetail;
  isOneDescription: boolean
}
const ToolOdtContent: FC<ToolOdtContentProps> = ({ tool, isOneDescription }) => {
  return (
    <div key={tool.id} className={isOneDescription ? "pptoc-tool-tall" : "pptoc-tool-small" }>
      <div className="pptoc-tool-content">
        <div className="pptoc-tool-icon">
          <img src={getStrapiImage(tool.picto.data.attributes)} alt="tool icon" />
        </div>
        <div key={tool.id} className={tool.description ? "pptoc-text-tall" : "pptoc-text-short"}>
          <div className="ago-principal-title-bold pptoc-tool-title">{tool.title}</div>
          {tool.description && (
            <ReactMarkdown
            className={'ago-text-regular-16 pptoc-tool-description ppcdz-generic-markdown-link'}
            children={tool.description.replace(/\n/g, '<br />')}
            rehypePlugins={[rehypeRaw]}
          />
        )}
        </div>
      </div>
      <div className="pptoc-tool-text-layout">
        {(tool?.buttonUrl || tool?.buttonName) && (
          <NJButton
            href={tool?.buttonUrl ?? '/missing_link'}
            target={tool.openNewTab ? "_blank": undefined}
            emphasis="subtle"
            variant="inverse"
            className="pptoc-tool-button"
          >
            <span data-child-name="customLabel">
              {tool?.buttonName ?? 'Access the tool'}
            </span>
          </NJButton>
        )}
      </div>
    </div>
  );
};
export { ToolOdtContent };
