import { number, object, string } from 'idonttrustlikethat';

const imageData = object({
  ext: string,
  url: string,
  hash: string,
  mime: string,
  name: string,
  path: string.nullable(),
  size: number,
  width: number,
  height: number,
});

export const imageFormats = object({
  large: imageData.optional(),
  medium: imageData.optional(),
  small: imageData.optional(),
  thumbnail: imageData.optional(),
});

export const imageFormat = object({
  data: object({
    attributes: object({
      ext: string,
      url: string,
      hash: string,
      mime: string,
      name: string,
      formats: imageFormats.optional().nullable(),
      path: string.nullable(),
      size: number,
      width: number,
      height: number,
    }),
  }).nullable(),
});
