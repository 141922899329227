import { array, object, string, number, boolean } from 'idonttrustlikethat';
import { strapiFileResponse } from '../../file/strapi-file';

const toolDetail = object({
  id : number,
  title: string,
  description : string.nullable(),
  picto : strapiFileResponse,
  buttonName: string.nullable(),
  buttonUrl: string.nullable(),
  openNewTab: boolean.nullable()
});
const toolsDetails = array(toolDetail);

type ToolDetail = typeof toolDetail.T;
type ToolsDetails = typeof toolsDetails.T;

export { toolDetail, toolsDetails };
export type { ToolDetail, ToolsDetails };

