import { literal, object, number, string, array } from 'idonttrustlikethat';
import { imageFormat } from '../../image-format';

const verticalStepperComponent = literal('blocks.vertical-stepper');
const verticalStepperBlock = object({
  id: number,
  __component: verticalStepperComponent,
  title: string,
  description: string.nullable(),
  image: imageFormat.nullable(),
  steps: array(
    object({
      title: string,
      subtitle: string.nullable(),
      description: string.nullable(),
    }),
  ),
  button: object({ label: string, link: string }).nullable(),
});

type VerticalStepperBlock = typeof verticalStepperBlock.T;
type VerticalStepperComponent = typeof verticalStepperComponent.T;

export { verticalStepperBlock, verticalStepperComponent };
export type { VerticalStepperBlock, VerticalStepperComponent };
