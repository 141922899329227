import { literal, number, object } from 'idonttrustlikethat';
import { domain } from '../domains/domain';

const dataDomainComponent = literal('blocks.data-domain');
const dataDomainBlock = object({
  id: number,
  __component: dataDomainComponent,
  domain: object({
    data: domain,
  }),
});

type DataDomainBlock = typeof dataDomainBlock.T;
type DataDomainComponent = typeof dataDomainComponent.T;

export { dataDomainBlock, dataDomainComponent };
export type { DataDomainBlock, DataDomainComponent };
