import { FC } from 'react';
import './indicators-odt-content.scss';

interface IndicatorItemProps {
  indicatorValue: string;
  indicatorName: string;
}

const IndicatorOdtContent: FC<IndicatorItemProps> = ({ indicatorValue, indicatorName }) => {
  return (
    <div className="indicator-item">
      <h3 className="ago-sub-tera-title indicator-value">{indicatorValue}</h3>
      <div className="indicator-line"></div>
      <p className="ago-text-regular-16 indicator-name">{indicatorName}</p>
    </div>
  );
};

export { IndicatorOdtContent };
