import React, { createContext, useContext, useState } from 'react';

interface BookViewerContextType {
  pageNumber: number;
  setPageNumber: (newPageNumber: number) => void;
}

const BookViewerContext = createContext<BookViewerContextType | null>(null);

const useBookViewer = () => {
  return useContext(BookViewerContext);
};

const BookViewerProvider = ({ children }) => {
  const [pageNumber, setPageNumberContext] = useState(0);

  const setPageNumber = (newPageNumber: number) => {
    setPageNumberContext(newPageNumber);
  };

  return (
    <BookViewerContext.Provider value={{ pageNumber, setPageNumber }}>
      {children}
    </BookViewerContext.Provider>
  );
};

export { type BookViewerContextType, BookViewerProvider, useBookViewer };
