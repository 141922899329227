import { ProgramPagesRouteInfo } from "../../../../../types/routing";
import { getParamLastSlug } from "./get-param-last-slug";

export const iscurrentlySelectedContent = (routeInfo: ProgramPagesRouteInfo, currentSlug: string): boolean => {
    if (getParamLastSlug(routeInfo) === currentSlug) {
        return true;
    } else {
        return false;
    }
}

export const isOrChildrenCurrentlySelectedContent = (routeInfo: ProgramPagesRouteInfo, currentSlug: string): boolean => {
    if (getParamLastSlug(routeInfo) === currentSlug) {
        return true;
    } else if (routeInfo.params.project_slug === currentSlug) {
        return true;
    } else {
        return false;
    }
}
