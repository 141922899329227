import { array, number, object, intersection } from 'idonttrustlikethat';
import { strapiCommonDates } from '../common/common-types';
import { yearBookBlocks } from '../component/blocks/year-book';

const yearBook = object({
  data: object({
    id: number,
    attributes: intersection(
      strapiCommonDates,
      object({
        blocks: array(yearBookBlocks),
      }),
    ),
  }),
});

type YearBook = typeof yearBook.T;

export { yearBook };
export type { YearBook };
