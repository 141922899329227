import { literal, object, number, string, boolean } from 'idonttrustlikethat';
import { strapiNullableFileResponse } from '../../file/strapi-file';

const callToActionOdtComponent = literal("blocks.call-to-action-odt");
const callToActionOdtBlock = object({
    id: number,
    __component: callToActionOdtComponent,
    title : string,
    description: string.nullable(),
    picto: strapiNullableFileResponse,
    buttonName: string.nullable(),
    buttonUrl: string.nullable(),
    openNewTab: boolean.nullable(),
    buttonIcon: strapiNullableFileResponse,
    isFullSize: boolean.nullable()
});

type CallToAtionOdtBlock = typeof callToActionOdtBlock.T;
type  CallToAtionOdtComponent = typeof callToActionOdtComponent.T;

export { callToActionOdtComponent, callToActionOdtBlock };
export type {  CallToAtionOdtBlock,  CallToAtionOdtComponent };