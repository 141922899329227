import { FC } from 'react';
import './keypoint-odt-content.scss';
import { getStrapiImage } from '../../../../utils/image';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { KeypointDetail } from '../../../../types/strapi/component/keypoint-odt/keypoint-odt';

interface KeypointOdtContentProps {
  keypoint: KeypointDetail
}

const KeypointOdtContent: FC<KeypointOdtContentProps> = ({ keypoint }) => {
  return (
    <div key={keypoint.id} className="ppkoc-keypoint">
      <div className="ppkoc-content">
        <div key={keypoint.id}>
          <div className="ppkoc-icon">
            <img
              src={getStrapiImage(keypoint.Picto.data.attributes)}
              alt="keypoint icon"
            />
          </div>
          <div className="ago-principal-title-bold ppkoc-title">{keypoint.title}</div>
          <ReactMarkdown
            className={'ago-text-regular-16 ppkoc-description'}
            children={keypoint.description.replace(/\n/g, '<br />')}
            rehypePlugins={[rehypeRaw]}
          />
        </div>
      </div>
    </div>
  );
};

export { KeypointOdtContent };
