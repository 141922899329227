import { FC, useEffect, useState } from 'react';
import { NewsCard } from '../../../atoms/card/news-card/news-card';
import { NJPagination } from '@engie-group/fluid-design-system-react';
import { NewsBlock } from '../../../../../types/strapi/component/blocks/news';
import { useHomeNewsBlock } from '../../../organisms/news/blocks/hooks/use-home-news-block';
import { getStrapiImage, IMG_FORMATS } from '../../../../../utils/image';
import { SimpleNews } from '../../../../../types/strapi/news/news';
import './snippet-news-block.scss';

interface SnippetNewsBlockProps {
  newsBlock: NewsBlock;
  onMount: (picture: string) => void;
  onHover: (picture: string) => void;
  onMouseLeave: () => void;
}

const SnippetNewsBlock: FC<SnippetNewsBlockProps> = ({
  newsBlock,
  onMount,
  onHover,
  onMouseLeave,
}) => {
  const [page, setPage] = useState<number>(1);
  const { newsResponse } = useHomeNewsBlock(newsBlock, page);
  const news = newsResponse.data;
  const isNewsRetrieved = news.length > 0;
  const isLatestNews = newsBlock.news.length === 0;
  const showPagination = news.length > 3;

  const paginateNews = (news: SimpleNews[], pageSize: number, pageNumber: number) => {
    return news.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };

  useEffect(() => {
    if (news.length > 0) {
      onMount(
        getStrapiImage(news[0].attributes.image.data?.attributes, IMG_FORMATS.full),
      );
    }
  }, [news]);

  return (
    <>
      {isNewsRetrieved &&
        paginateNews(news, 3, isLatestNews ? 1 : page).map((simpleNews) => (
          <NewsCard
            key={`snippet-news-${simpleNews.id}`}
            {...{
              simpleNews,
              onHover: onHover,
              onMouseLeave: onMouseLeave,
            }}
          />
        ))}
      {(isLatestNews || (isNewsRetrieved && showPagination)) && (
        <div className="ago-pagination-container">
          <NJPagination
            ariaLabelNext="Next news"
            ariaLabelPrevious="Previous news"
            count={isLatestNews ? 2 : Math.ceil(news.length / 3)}
            onChange={(pageNumber) => {
              setPage(pageNumber);
            }}
          />
        </div>
      )}
    </>
  );
};

export { SnippetNewsBlock };
