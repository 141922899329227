import { FC, MouseEventHandler, memo } from 'react';
import { NJCard } from '@engie-group/fluid-design-system-react';
import { SimpleNews } from '../../../../../types/strapi/news/news';
import { getStrapiImage, IMG_FORMATS } from '../../../../../utils/image';
import './news-card.scss';
import { dateFormat } from '../../../../../utils/date';
import {} from 'react-dom';
interface NewsCardProps {
  simpleNews: SimpleNews;
  onHover?: (picture: string) => void;
  onMouseLeave?: MouseEventHandler<HTMLAnchorElement>;
  HasDescription?: boolean;
  className?: string;
  containerHeight?: string;
  hasBottomBorder?: boolean;
  isHorizontal?: boolean;
}
const style = (containerHeight: string) => {
  return {
    '--ago-news-container-height': `${containerHeight}`,
  } as React.CSSProperties;
};
const NewsCard: FC<NewsCardProps> = memo(
  ({
    simpleNews,
    onHover,
    onMouseLeave,
    HasDescription = false,
    className,
    containerHeight = '11.25rem',
    hasBottomBorder,
    isHorizontal = true,
  }) => {
    const title = simpleNews.attributes.title;
    const newsBackground = getStrapiImage(
      simpleNews.attributes.image.data?.attributes,
      IMG_FORMATS.full,
    );
    const newsPicture = getStrapiImage(simpleNews.attributes.image.data?.attributes);
    const dynamicClassPosition = isHorizontal
      ? 'card--horizontal-position'
      : 'card--vertical-position';
    return (
      <a
        href={`/news/${simpleNews.attributes.slug}`}
        className={`${className} ${dynamicClassPosition}`}
        onMouseEnter={() => onHover?.(newsBackground)}
        onMouseLeave={onMouseLeave}
        style={style(containerHeight)}
      >
        <NJCard
          {...{
            className: 'news-card',
            hasBottomBorder,
            image: (
              <img
                {...{
                  alt: title,
                  src: newsPicture,
                }}
              />
            ),
            isHorizontal,
          }}
        >
          <div className="card-container">
            <p className="ago-big-p-body">
              Published on {dateFormat(simpleNews.attributes?.publication_date)}
            </p>
            <h3 className="ago-principal-title-bold ago-restriction-lines">{title}</h3>
            <p className="ago-text-bold-blue-16">
              {`Created by ${simpleNews.attributes?.author?.data?.attributes?.firstname} ${simpleNews.attributes?.author?.data?.attributes?.lastname}`}
            </p>
            {HasDescription && (
              <p className="ago-text-regular-16 ago-restriction-lines">
                {simpleNews.attributes.description}
              </p>
            )}
          </div>
        </NJCard>
      </a>
    );
  },
);

export { NewsCard };
