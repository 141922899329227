import { useMemo } from 'react';
import { VideoODTBlock } from '../../../../../types/strapi/component/blocks/video-odt';

const useVideo = (videoData: VideoODTBlock) => {
  const videosList = useMemo(
    () => videoData.video_links,
    [videoData.video_links],
  );

  return {
    videosList,
  };
};

export { useVideo };
