import { literal, object, number, string } from 'idonttrustlikethat';
import { toolsDetails } from '../tool-odt/tool-odt';

const toolsOdtComponent = literal("blocks.tools-odt");
const toolsOdtBlock = object({
    id: number,
    __component: toolsOdtComponent,
    title : string,
    tools: toolsDetails
});

type ToolsOdtBlock = typeof toolsOdtBlock.T;
type  ToolsOdtComponent = typeof toolsOdtComponent.T;

export { toolsOdtBlock, toolsOdtComponent };
export type {  ToolsOdtBlock,  ToolsOdtComponent };