import { array, number, object, string } from 'idonttrustlikethat';
import { headerODTSingleBlock } from '../component/blocks/header-odt';
import { menuProgramPageResponce } from './menu-program-page/menu-program-page';

const programMainPage = object({
  slug: string,
  menu: menuProgramPageResponce,
  header: headerODTSingleBlock,
});

const programMainPageResponce = object({
  id: number,
  attributes: programMainPage,
});

const programMainPagesResponce = object({
  data: array(programMainPageResponce),
});

type ProgramMainPagesResponce = typeof programMainPagesResponce.T;
type ProgramMainPageResponce = typeof programMainPageResponce.T;
type ProgramMainPage = typeof programMainPage.T;

export { programMainPagesResponce, programMainPageResponce, programMainPage };
export type { ProgramMainPagesResponce, ProgramMainPageResponce, ProgramMainPage };
