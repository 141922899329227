import { string, object, number, boolean, array } from 'idonttrustlikethat';
import { imageFormat } from '../../image-format';
import { strapiFileAttributes } from '../../file/strapi-file';

const domain = object({
  id: number,
  attributes: object({
    title: string,
    description: string,
    inverted: boolean,
    background: imageFormat,
    attachements: object({ data: array(strapiFileAttributes).nullable() }).nullable(),
    createdAt: string,
    updatedAt: string.nullable(),
    publishedAt: string,
  }),
});

type Domain = typeof domain.T;

export { domain };
export type { Domain };
