import { FC, useEffect, useState } from 'react';
import './book.scss';
import { BookViewerContextType, useBookViewer } from './book-viewer-context';
import { useWindowsSizeBlock } from '../../../../utils/hooks/use-windows-size-block';
import { IBookDimensions, IBookPage } from './book-viewer-types';
import { NJBookPage } from './book-page';

interface NJBookProps {
  bookFile: IBookPage[];
}

const NJBook: FC<NJBookProps> = ({ bookFile }) => {
  const { pageNumber } = useBookViewer() as BookViewerContextType;
  const { width } = useWindowsSizeBlock();
  const getDimensions = () => {
    let newDimensions: IBookDimensions | null = null;

    if (width >= 1600) {
      newDimensions = {
        pageWidthREM: 43.75,
        pageHeightREM: 30.938,
      };
    } else if (width >= 1300) {
      newDimensions = {
        pageWidthREM: 34.375,
        pageHeightREM: 24.313,
      };
    } else if (width >= 900) {
      newDimensions = {
        pageWidthREM: 21.875,
        pageHeightREM: 15.375,
      };
    } else if (width >= 768) {
      newDimensions = {
        pageWidthREM: 18.75,
        pageHeightREM: 13.25,
      };
    } else {
      newDimensions = {
        pageWidthREM: 6.25,
        pageHeightREM: 4.438,
      };
    }

    return newDimensions;
  };
  const [dimensions, setDimensions] = useState(getDimensions());

  useEffect(() => {
    const newDimensions = getDimensions();

    setDimensions({ ...newDimensions });
  }, [width]);

  useEffect(() => {
    const container = document.getElementById(`book-page-${pageNumber}`);

    if (container) {
      container.scrollIntoView({ block: 'center' });
    }
  }, [dimensions]);

  return (
    <div
      className="book-container"
      style={{
        height: `${dimensions.pageHeightREM}rem`,
      }}
    >
      {bookFile.map((page, index) => {
        return (
          <div id={`book-page-${index}`} className="book-container__wrapper">
            <NJBookPage page={page} dimensions={dimensions} />
          </div>
        );
      })}
    </div>
  );
};

export { NJBook };
