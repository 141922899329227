import { VideoODTBlock } from '../../../../types/strapi/component/blocks/video-odt';
import './video-odt-block.scss';
import { FC, useState } from 'react';
import { useVideo } from './hooks/use-video';
import VideoPlaceholder from '../../../../images/jpg/video-placeholder.jpg';
import { TypeVideo, useTypesVideos } from './hooks/use-types-videos';
import { useWindowsSizeBlock } from '../../../../utils/hooks/use-windows-size-block';
import PlayerVideo from '../../atoms/player-video/player-video';
import { referalApiWS } from '../../../../config/unsplash';
import { NJButton } from '@engie-group/fluid-design-system-react';

interface VideosODTBlockProps {
  videoData: VideoODTBlock;
}

const VideosODTBlock: FC<VideosODTBlockProps> = ({ videoData }) => {
  const { videosList } = useVideo(videoData);
  const { typedVideos } = useTypesVideos(videosList);

  const { width } = useWindowsSizeBlock();
  const videoHeight = width > 1300 ? '578px' : '400px';

  const [watchedVideo, setWatchedVideo] = useState(typedVideos[0].url);
  const [typeOfVideo, setTypeOfVideo] = useState(typedVideos[0].type);
  const [title, setTitle] = useState(typedVideos[0].title);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);

  const loadVideoClicked = (
    url: string,
    type: TypeVideo,
    title: string,
    selectedIndex: number,
  ) => {
    setIsPlayingVideo(false);
    setWatchedVideo(url);
    setTypeOfVideo(type);
    setTitle(title);
    setSelectedIndex(selectedIndex);
  };

  const activeVideoClass = (index: number) => {
    return selectedIndex === index ? 'ppvob-caption--active' : '';
  };

  typeOfVideo === TypeVideo.SHAREPOINT_ENGIE &&
    isPlayingVideo !== true &&
    setIsPlayingVideo(true);

  return (
    <div className="ppvob-layout">
      <div className="ppvob-size">
        {!!videoData.title && (
          <h2 className="ago-sub-tera-title ppvob-title">
            {videoData.title}
          </h2>
        )}

        <div className="ppvob-container">
          {
            <PlayerVideo
              url={watchedVideo}
              width="100%"
              height={videoHeight}
              iframe={typeOfVideo === TypeVideo.SHAREPOINT_ENGIE}
              title={title}
              onPlay={() => setIsPlayingVideo(true)}
              onPause={() => setIsPlayingVideo(false)}
            />
          }

          <div className="ppvob-list-videos">
            {typedVideos
              .sort((a, b) => a.order - b.order)
              .map((video, index) => {
                return (
                  <div
                    key={video.title.toLowerCase()}
                    className={`ppvob-caption <${activeVideoClass(index)}>`}
                    onClick={() =>
                      loadVideoClicked(video.url, video.type, video.title, index)
                    }
                  >
                    <img
                      className="ppvob-caption-image"
                      src={
                        video.video_caption.data
                          ? `${referalApiWS}${video.video_caption.data.attributes.url}`
                          : VideoPlaceholder
                      }
                      alt="video first frame"
                    />{' '}
                    {activeVideoClass(index) ? (
                      <div className="ppvob-animation-watch">
                        <section
                          style={
                            {
                              '--white-bar-animation-state': isPlayingVideo
                                ? 'running'
                                : 'paused',
                            } as React.CSSProperties
                          }
                          className="ppvob-container-animation-video"
                        >
                          <span className="ppvob-animated-white-bar-1"></span>
                          <span className="ppvob-animated-white-bar-2"></span>
                          <span className="ppvob-animated-white-bar-3"></span>
                        </section>
                      </div>
                    ) : (
                      <></>
                    )}
                    <h3 className="ago-principal-title-bold ppvob-video-caption-title">
                      {video.title}
                    </h3>
                  </div>
                );
              })}
          </div>
        </div>
        {(!!videoData.buttonUrl || !!videoData.buttonName) && (
          <div className="ppvob-button-video">
            <NJButton
              href={
                videoData.buttonUrl ??
                'https://engie.sharepoint.com/sites/OneDataTeamCommunication'
              }
              target={videoData.openNewTab ? "_blank": undefined}
              variant="primary"
              label={videoData.buttonName ?? 'More videos'}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideosODTBlock;
