import React, { FC } from 'react';
import { RichTextBlock } from '../../../../types/strapi/component/blocks/rich-text';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import './rich-text-block.scss';

interface RichTextDisplayBlockProps {
  richTextBlock: RichTextBlock;
}

const RichTextDisplayBlock: FC<RichTextDisplayBlockProps> = ({ richTextBlock }) => {
  return (
    <div className="rich-text-container">
      <ReactMarkdown
        children={richTextBlock.content}
        rehypePlugins={[rehypeRaw]}
      ></ReactMarkdown>
    </div>
  );
};

export { RichTextDisplayBlock };
