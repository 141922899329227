
import { FC } from 'react';
import './keypoints-odt-block.scss';
import { KeypointsOdtBlock } from '../../../../types/strapi/component/blocks/keypoints-odt';
import { KeypointOdtContent } from '../../molecules/keypoint-odt/keypoint-odt-content';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

interface KeypointsOdtBlockProps {
  keypointsBlock: KeypointsOdtBlock;
}

const KeypointsOdtDisplayBlock: FC<KeypointsOdtBlockProps> = ({ keypointsBlock }) => {
  return (
    <div className="ppkob-block">
      <div className="ppkob-infos">
        <h2 className="ago-sub-tera-title ppkob-title">{keypointsBlock.title}</h2>
        <ReactMarkdown
          className={'ago-text-regular-16 iodb-description ppcdz-generic-markdown-link'}
          children={keypointsBlock?.description?.replace(/\n/g, '<br />')}
          rehypePlugins={[rehypeRaw]}
        />
      </div>
      
      <div className="ppkob-content">
        <div className="ppkob-section">
          {keypointsBlock.keypoints.map((keypoint, index) => (
            <KeypointOdtContent key={index} keypoint={keypoint} />
          ))}
        </div>
      </div>
    </div>
  );
};

export { KeypointsOdtDisplayBlock };