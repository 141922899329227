import { boolean, literal, number, object, string } from "idonttrustlikethat";
import { strapiNullableFileResponse } from "../../file/strapi-file";

const introductionODTPPComponent = literal("blocks.introduction-gdp");
const introductionODTPPBlock = object({
  id: number,
  __component: introductionODTPPComponent,
  title: string.nullable(),
  description: string.nullable(),
  image: strapiNullableFileResponse.nullable(),
  iconDownload: strapiNullableFileResponse.nullable(),
  urlDownload: string.nullable(),
  descriptionDownload: string.nullable(),
  downloadOpenNewTab: boolean.nullable()
});

type IntroductionODTPPBlock = typeof introductionODTPPBlock.T;
type IntroductionODTPPComponent = typeof introductionODTPPComponent.T;

export { introductionODTPPBlock, introductionODTPPComponent };
export type { IntroductionODTPPBlock, IntroductionODTPPComponent };
