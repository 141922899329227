import { array, object, string, number } from 'idonttrustlikethat';
import { strapiFileResponse } from '../../file/strapi-file';

const keypointDetail = object({
  id : number,
  title: string,
  description : string,
  Picto : strapiFileResponse
});
const keypointsDetails = array(keypointDetail);

type KeypointDetail = typeof keypointDetail.T;
type KeypointsDetails = typeof keypointsDetails.T;

export { keypointDetail, keypointsDetails };
export type { KeypointDetail, KeypointsDetails };

