import { FC } from 'react';
import './vertical-stepper-steps.scss';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

interface VerticalStepperStepsBlockProps {
  steps: {
    title: string;
    subtitle?: string | null;
    description?: string | null;
  }[];
}

const VerticalStepperSteps: FC<VerticalStepperStepsBlockProps> = ({ steps }) => {
  return (
    <div className="vertical-stepper-steps">
      {steps.map((step, index) => (
        <div className="vertical-stepper-steps__step">
          <div className="vertical-stepper-steps__step__tail">
            <span className="vertical-stepper-steps__step__tail__index">{index + 1}</span>
            <h2 className="vertical-stepper-steps__step__tail__title">{step.title}</h2>
            {step.subtitle && (
              <p className="vertical-stepper-steps__step__tail__subtitle">
                {step.subtitle}
              </p>
            )}
          </div>
          {step.description && (
            <ReactMarkdown
              className="vertical-stepper-steps__step__body ppcdz-generic-markdown-link"
              children={step.description}
              rehypePlugins={[rehypeRaw]}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export { VerticalStepperSteps };
