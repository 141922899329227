import { array, literal, number, object, string } from 'idonttrustlikethat';
import { domain } from '../domains/domain';

const domainsListComponent = literal('blocks.domains-list');
const domainsListBlock = object({
  id: number,
  __component: domainsListComponent,
  title: string,
  description: string,
  domains: object({
    data: array(domain),
  }),
});

type DomainsListBlock = typeof domainsListBlock.T;
type DomainsListComponent = typeof domainsListComponent.T;

export { domainsListBlock, domainsListComponent };
export type { DomainsListBlock, DomainsListComponent };
