import { literal, object, number, string } from 'idonttrustlikethat';
import { indicatorsList } from '../indicator-odt/indicator-odt-list';

const indicatorsOdtComponent = literal("blocks.indicators-odt");
const indicatorsOdtBlock = object({
  id: number,
  __component: indicatorsOdtComponent,
  indicators_list: indicatorsList,
  title: string.nullable()
});

type IndicatorsOdtBlock = typeof indicatorsOdtBlock.T;
type IndicatorsOdtComponent = typeof indicatorsOdtComponent.T;

export { indicatorsOdtBlock, indicatorsOdtComponent };
export type { IndicatorsOdtBlock, IndicatorsOdtComponent };