
import { FC } from 'react';
import './tools-odt-block.scss';
import { ToolsOdtBlock } from '../../../../types/strapi/component/blocks/tools-odt';
import { ToolOdtContent } from '../../molecules/tool-odt/tool-odt-content';

interface ToolsOdtBlockProps {
  toolsBlock: ToolsOdtBlock;
}

const ToolsOdtDisplayBlock: FC<ToolsOdtBlockProps> = ({ toolsBlock }) => {
  const isOneDescription= !!toolsBlock.tools.find(tool => tool.description)
  return (
    <div className="pptob-layout">
      <div className="ago-principal-title-bold pptob-title">{toolsBlock.title}</div>
      <div className="pptob-content">
        <div
          className={`pptob-section ${
            isOneDescription ? 'pptob-section-type-tall' : 'pptob-section-type-small'
          }`}
        >
          {toolsBlock.tools.map((tool, index) => (
            <ToolOdtContent key={index} tool={tool} isOneDescription={isOneDescription} />
          ))}
        </div>
      </div>
    </div>
  );
};

export { ToolsOdtDisplayBlock };