import { appEnv } from '../build/validation';
import { referalApiWS } from '../config/unsplash';
import PlaceHolderPicture from '../images/jpg/video-placeholder.jpg';
import { IDTLTStrapiFile } from '../types/strapi/file/strapi-file';

export enum IMG_FORMATS {
  full,
  large,
  medium,
  small,
  thumbnail,
}

const fullUrl = (urlImage: string | undefined) => {
  return urlImage
    ? urlImage.startsWith('http')
      ? urlImage
      : `${appEnv.backendUrl}${urlImage}`
    : '';
};

const getStrapiImageUrl = (imageUrl: string | undefined) => {
  return imageUrl ? `${referalApiWS}${imageUrl}` : PlaceHolderPicture;
};

const getStrapiImage = (
  img: IDTLTStrapiFile | undefined,
  format: IMG_FORMATS = IMG_FORMATS.small,
) => {
  let imgSrc = '';

  if (img !== undefined) {
    switch (format) {
      case IMG_FORMATS.full:
        imgSrc = img.url;
        break;
      case IMG_FORMATS.large:
        imgSrc = img.formats?.large?.url ?? img.url;
        break;
      case IMG_FORMATS.medium:
        imgSrc = img.formats?.medium?.url ?? img.url;
        break;
      case IMG_FORMATS.small:
        imgSrc = img.formats?.small?.url ?? img.url;
        break;
      case IMG_FORMATS.thumbnail:
        imgSrc = img.formats?.thumbnail?.url ?? img.url;
        break;
      default:
        imgSrc = img.formats?.small?.url ?? img.url;
        break;
    }
  } else {
    return imgSrc;
  }

  return getStrapiImageUrl(imgSrc);
};

export { fullUrl, getStrapiImage, getStrapiImageUrl };
