import { FC } from 'react';
import { ProgramAndProjectPagesResponce } from '../../../../../types/strapi/program-pages/content-program-page/content-program-page';
import { ProgramAndProjectDynamicZone } from './program-and-project-content-dynamic-zone';
import NotFound from '../../../../../pages/NotFound/NotFound';

interface ProgramPagesContextProps {
  pageContent: ProgramAndProjectPagesResponce;
}

const ProgramAndProjectContentValidator: FC<ProgramPagesContextProps> = ({
  pageContent,
}) => {
  return (
    <>
      {!!pageContent.data.length ? (
        <ProgramAndProjectDynamicZone blocks={pageContent.data[0].attributes.blocks} />
      ) : (
        <NotFound />
      )}
    </>
  );
};

export { ProgramAndProjectContentValidator };