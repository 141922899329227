import { array, intersection, number, object, string } from 'idonttrustlikethat';
import { programPagesBlocks } from '../../component/blocks/program-pages-block';

const programAndProjectPage = intersection(
  object({
    slug: string,
    blocks: array(programPagesBlocks),
  }),
);

const programAndProjectPageResponce = object({
  id: number,
  attributes: programAndProjectPage,
});

const programAndProjectPagesResponce = object({
  data: array(programAndProjectPageResponce),
});

type ProgramAndProjectPagesResponce = typeof programAndProjectPagesResponce.T;
type ProgramAndProjectPageResponce = typeof programAndProjectPageResponce.T;
type ProgramAndProjectPage = typeof programAndProjectPage.T;

export {
  programAndProjectPagesResponce,
  programAndProjectPageResponce,
  programAndProjectPage,
};
export type {
  ProgramAndProjectPagesResponce,
  ProgramAndProjectPageResponce,
  ProgramAndProjectPage,
};