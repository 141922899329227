import { FC } from 'react';
import './book-page.scss';
import { IBookDimensions, IBookPage } from './book-viewer-types';
import { NJBookPageAnnotation } from './book-page-annotation';

interface NJBookPageProps {
  page: IBookPage;
  dimensions: IBookDimensions;
}

const NJBookPage: FC<NJBookPageProps> = ({ page, dimensions }) => {
  return (
    <div className="book-page">
      <img
        alt={`Engie book page ${page.id}`}
        src={page.image}
        style={{
          width: `${dimensions.pageWidthREM}rem`,
          height: `${dimensions.pageHeightREM}rem`,
        }}
      />
      {page.annotations &&
        page.annotations.map((annotation) => (
          <NJBookPageAnnotation
            page={page}
            dimensions={dimensions}
            annotation={annotation}
          />
        ))}
    </div>
  );
};

export { NJBookPage };
