import { union } from 'idonttrustlikethat';
import { headerODTBlock, headerODTComponent } from './header-odt';
import { bookPDFViewerBlock, bookPDFViewerComponent } from './book-pdf-viewer';
import { richTextBlock, richTextComponent } from './rich-text';

const yearBookBlocksComponents = union(
  headerODTComponent,
  bookPDFViewerComponent,
  richTextComponent,
);
const yearBookBlocks = union(headerODTBlock, bookPDFViewerBlock, richTextBlock);

type YearBookBlocks = typeof yearBookBlocks.T;
type YearBookComponents = typeof yearBookBlocksComponents.T;

export { yearBookBlocks, yearBookBlocksComponents };
export type { YearBookBlocks, YearBookComponents };
