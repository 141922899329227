import { FC } from 'react';
import { MenuProgramPage } from '../../../../../types/strapi/program-pages/menu-program-page/menu-program-page';
import './menu-program-pages-list.scss';
import { ProgramPagesRouteInfo } from '../../../../../types/routing';
import { generateRedirectionUrl } from '../utils/generate-redirection-url';
import { MenuListProject } from './list/menu-list-project';
import { iscurrentlySelectedContent } from '../utils/is-currently-selected-content';
import { MenuListAccordion } from './list/menu-list-accordion';

interface MenuProgramPagesListProps {
  menu: MenuProgramPage;
  routeInfo: ProgramPagesRouteInfo;
}

const MenuProgramPagesList: FC<MenuProgramPagesListProps> = ({ menu, routeInfo }) => {

  return (
    <>
      <div className="mppl-layout">
        <MenuListAccordion
          className={`mppl-offset-program mppl-box ${iscurrentlySelectedContent(routeInfo, menu.programSlug)
              ? ' mppl-box-selected'
              : ' mppl-box-not-selected'}`}
          href={generateRedirectionUrl(menu.programSlug)}
          parent={
            <span className="mppl-bold mppl-text ago-text-regular-16 ">
              {menu.programMenuName}
            </span>
          }
          childrens={menu.projects.map((project) => (
            <MenuListProject
              menu={menu}
              project={project}
              routeInfo={routeInfo}
            />
          ))}
        />
      </div>
      <div className="mppl-void" />
    </>
  );
};

export { MenuProgramPagesList };
