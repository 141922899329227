import { literal, number, object } from 'idonttrustlikethat';
import { strapiFileResponse } from '../../file/strapi-file';

const bookPDFViewerComponent = literal('blocks.book-pdf-viewer');
const bookPDFViewerBlock = object({
  id: number,
  __component: bookPDFViewerComponent,
  file: strapiFileResponse,
});

type BookPDFViewerBlock = typeof bookPDFViewerBlock.T;
type BookPDFViewerComponent = typeof bookPDFViewerComponent.T;

export { bookPDFViewerBlock, bookPDFViewerComponent };
export type { BookPDFViewerBlock, BookPDFViewerComponent };
