import { FC } from 'react';
import { DataFetcher } from '../../components/atomic/templates/data-fetcher';
import { referalApiWS } from '../../config/unsplash';
import StrapiRoutes from '../../services/strapi/strapiRoutes';
import { YearBookDynamicZone } from '../../components/atomic/templates/year-book/year-book-dynamic-zone';
import { yearBook } from '../../types/strapi/year-book/year-book';

interface YearBookProps {}

const YearBook: FC<YearBookProps> = () => {
  return (
    <>
      <DataFetcher
        {...{
          route: `${referalApiWS}/api/${StrapiRoutes.yearBook}`,
          params: {
            populate: [
              'blocks',
              'blocks.background',
              'blocks.backgroundSmall',
              'blocks.file',
              'blocks.pdf_worker',
            ],
          },
          validator: yearBook,
        }}
      >
        {(data) => (
          <YearBookDynamicZone
            {...{
              blocks: data.data.attributes.blocks,
            }}
          />
        )}
      </DataFetcher>
    </>
  );
};

export default YearBook;
