import { FC } from 'react';
import { MenuProgramPage } from '../../../../../types/strapi/program-pages/menu-program-page/menu-program-page';
import { ProgramPagesRouteInfo } from '../../../../../types/routing';
import { NJIcon, NJListItem, NJSelectInput } from '@engie-group/fluid-design-system-react';
import "./menu-program-pages-selector.scss"
import { generateRedirectionUrl } from '../utils/generate-redirection-url';

interface MenuProgramPagesSelectorProps {
  menu: MenuProgramPage;
  routeInfo: ProgramPagesRouteInfo;
  isSticky: boolean;
}

const MenuProgramPagesSelector: FC<MenuProgramPagesSelectorProps> = ({
  menu,
  routeInfo,
  isSticky
}) => {
  const generateMenuListItem = () => {
    const itemList: JSX.Element[] = [];
    itemList.push(
      <NJListItem aria-selected="false" className="mpps-selectable mpps-program" value={generateRedirectionUrl(menu.programSlug)}>
        {menu.programMenuName}
      </NJListItem>,
    );
    menu.projects.forEach((project) => {
      itemList.push(
        <NJListItem
          aria-selected="false"
          className="mpps-selectable mpps-offset-project"
          value={generateRedirectionUrl(menu.programSlug, project.projectSlug)}
        >
          {project.projectMenuName}
        </NJListItem>,
      );
      project.subProjects.forEach((subProject) => {
        itemList.push(
          <NJListItem
            aria-selected="false"
            className="mpps-selectable mpps-offset-subproject"
            value={generateRedirectionUrl(
              menu.programSlug,
              project.projectSlug,
              subProject.subProjectSlug,
            )}
          >
            {subProject.subProjectMenuName}
          </NJListItem>,
        );
      });
    });
    return itemList;
  };

  return (
    <div className="mpps-selector-block">
      <div className="mpps-selector-layout">
        <NJSelectInput
          className="mpps-selector"
          buttonDefaultValueLabel=""
          label="Program sections"
          value={generateRedirectionUrl(
            routeInfo.params.slug,
            routeInfo.params.project_slug,
            routeInfo.params.subproject_slug,
          )}
          onChange={(value: any) => {
            window.location.href = value;
          }}
          id={'Program sections'}
          listNavigationLabel={''}
        >
          {generateMenuListItem()}
        </NJSelectInput>
        <span className="mpps-text">
          Navigate between sections using this selection tool
        </span>
        {isSticky && (
          <div
            className="mpps-to-tp-button"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }}
          >
            <NJIcon name="expand_less" size="xxl" variant="inherit" />
          </div>
        )}
      </div>
    </div>
  );
};

export { MenuProgramPagesSelector };