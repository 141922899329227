import { FC } from 'react';
import './call-to-action-odt-block.scss';
import { CallToAtionOdtBlock } from '../../../../types/strapi/component/blocks/call-to-action-odt';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { NJButton } from '@engie-group/fluid-design-system-react';
import { referalApiWS } from '../../../../config/unsplash';

interface CallToActionODTBlockProps {
  callToAtionBlock: CallToAtionOdtBlock;
}

const CallToActionOdtDisplayBlock: FC<CallToActionODTBlockProps> = ({
  callToAtionBlock,
}) => {
  return (
    <div className={`ppctaob-container${callToAtionBlock.isFullSize ? " ppctaob-full-size-background" : ""}`}>
      <div className="ppctaob-size">
        <div className="ppctaob-layout">
          <div className="ppctaob-text-layout">
            <div className="ago-principal-title-bold ppctaob-title">
              {callToAtionBlock.title}
            </div>

            <ReactMarkdown
              className={'ago-text-regular-16 ppctaob-description ppcdz-generic-markdown-link'}
              children={callToAtionBlock?.description?.replace(/\n/g, '<br />')}
              rehypePlugins={[rehypeRaw]}
            />
            {(callToAtionBlock?.buttonUrl || callToAtionBlock?.buttonName) && (
              <NJButton
                href={callToAtionBlock?.buttonUrl ?? '/missing_link'}
                target={callToAtionBlock.openNewTab ? '_blank' : undefined}
                emphasis="subtle"
                variant="inverse"
                className="ppctaob-button"
              >
                <span data-child-name="customLabel" className="ppctaob-button-content">
                  {callToAtionBlock?.buttonIcon?.data && (
                    <img
                      src={`${referalApiWS}${callToAtionBlock?.buttonIcon?.data?.attributes.url}`}
                      alt="dl introduction pp"
                    />
                  )}
                  {callToAtionBlock?.buttonName ?? 'Go To'}
                </span>
              </NJButton>
            )}
          </div>
          <div className="ppctaob-image-layout">
            {callToAtionBlock?.picto?.data && (
              <img
                className="ppctaob-image"
                src={`${referalApiWS}${callToAtionBlock?.picto?.data?.attributes.url}`}
                alt="dl introduction pp"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { CallToActionOdtDisplayBlock };
