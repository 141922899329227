import { FC } from 'react';
import './../menu-program-pages-list.scss';
import {
  MenuProgramPage,
  MenuProject,
  MenuSubProject,
} from '../../../../../../types/strapi/program-pages/menu-program-page/menu-program-page';
import { ProgramPagesRouteInfo } from '../../../../../../types/routing';
import { generateRedirectionUrl } from '../../utils/generate-redirection-url';
import { iscurrentlySelectedContent } from '../../utils/is-currently-selected-content';

interface MenuListSubProjectProps {
  menu: MenuProgramPage;
  project: MenuProject;
  subProject: MenuSubProject;
  routeInfo: ProgramPagesRouteInfo;
}

const MenuListSubProject: FC<MenuListSubProjectProps> = ({
  menu,
  project,
  subProject,
  routeInfo,
}) => {
  return (
    <div
      className={`mppl-offset-subproject mppl-box${
        iscurrentlySelectedContent(routeInfo, subProject.subProjectSlug)
          ? ' mppl-box-selected'
          : ' mppl-box-not-selected'
      }`}
    >
      <a
        href={generateRedirectionUrl(
          menu.programSlug,
          project.projectSlug,
          subProject.subProjectSlug,
        )}
      >
        <span className="mppl-text">{subProject.subProjectMenuName}</span>
      </a>
    </div>
  );
};

export { MenuListSubProject };
