import { FC } from 'react';
import './book-pdf-viewer-block.scss';
import { IBookDimensions, IBookPage, IBookPageAnnotation } from './book-viewer-types';

interface NJBookPageAnnotationProps {
  page: IBookPage;
  dimensions: IBookDimensions;
  annotation: IBookPageAnnotation;
}

const NJBookPageAnnotation: FC<NJBookPageAnnotationProps> = ({
  page,
  dimensions,
  annotation,
}) => {
  const widthRatio = (dimensions.pageWidthREM * 16) / page.viewportSize.width;
  const heightRatio = (dimensions.pageHeightREM * 16) / page.viewportSize.height;

  return (
    <a
      href={annotation.url}
      target="_blank"
      rel="noreferrer"
      style={{
        left: `${annotation.rect[0] * widthRatio}px`,
        top: `${(page.viewportSize.height - annotation.rect[3]) * heightRatio}px`,
        width: `${(annotation.rect[2] - annotation.rect[0]) * widthRatio}px`,
        height: `${(annotation.rect[3] - annotation.rect[1]) * heightRatio}px`,
      }}
    >
      {' '}
    </a>
  );
};

export { NJBookPageAnnotation };
