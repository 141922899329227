import { literal, number, object, string } from 'idonttrustlikethat';

const richTextComponent = literal('blocks.rich-text');
const richTextBlock = object({
  id: number,
  __component: richTextComponent,
  content: string,
});

type RichTextBlock = typeof richTextBlock.T;
type RichTextComponent = typeof richTextComponent.T;

export { richTextBlock, richTextComponent };
export type { RichTextBlock, RichTextComponent };
