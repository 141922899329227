import { FC } from 'react';
import './year-book-dynamic-zone.scss';
import { headerODTComponent } from '../../../../types/strapi/component/blocks/header-odt';
import { HeaderODTDisplayBlock } from '../../organisms/header-odt/blocks/header-odt-block';
import { YearBookBlocks } from '../../../../types/strapi/component/blocks/year-book';
import { bookPDFViewerComponent } from '../../../../types/strapi/component/blocks/book-pdf-viewer';
import { BookPDFViewerDisplayBlock } from '../../organisms/book-pdf-viewer/book-pdf-viewer-block';
import { BookViewerProvider } from '../../organisms/book-pdf-viewer/book-viewer-context';
import { richTextComponent } from '../../../../types/strapi/component/blocks/rich-text';
import { RichTextDisplayBlock } from '../../organisms/rich-text/rich-text-block';

interface YearBookDynamicZoneProps {
  blocks: YearBookBlocks[];
}

const YearBookDynamicZone: FC<YearBookDynamicZoneProps> = ({ blocks }) => {
  return (
    <>
      {blocks.map((block: YearBookBlocks) => {
        switch (block.__component) {
          case headerODTComponent.literal:
            return (
              <HeaderODTDisplayBlock
                headerODTBlock={block}
                pathList={[
                  { url: '/onedatateam', label: 'One Data Team' },
                  { url: '/onedatateam/yearbook', label: 'Book of Achievements' },
                ]}
              />
            );
          case bookPDFViewerComponent.literal:
            return (
              <BookViewerProvider>
                <BookPDFViewerDisplayBlock bookPDFViewerBlock={block} />
              </BookViewerProvider>
            );
          case richTextComponent.literal:
            return <RichTextDisplayBlock richTextBlock={block} />;
          default:
            return <p>Hello World</p>;
        }
      })}
    </>
  );
};

export { YearBookDynamicZone };
