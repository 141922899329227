import { FC, useState } from 'react';
import './../menu-program-pages-list.scss';
import './menu-list-accordion.scss';
import { NJIcon } from '@engie-group/fluid-design-system-react';

interface MenuListAccordionProps {
  href: string;
  className: string | undefined;
  parent: JSX.Element;
  childrens: JSX.Element[];
}

const MenuListAccordion: FC<MenuListAccordionProps> = ({
  href,
  className,
  parent,
  childrens,
}) => {
  const [isopen, setIsOpen] = useState(true);

  return (
    <>
      <div className={className}>
        {!!childrens.length ? (
          <div
            className={`mppla-expand-icon${
              isopen ? ' mppla-icon-normal' : ' mppla-icon-reverse'
            }`}
            onClick={() => {
              setIsOpen(!isopen);
            }}
          >
            <NJIcon size="lg" name="arrow_drop_up" />
          </div>
        ) : (
          <div className="mppl-offset-no-icon" />
        )}
        <a href={href}
        >
          {parent}
        </a>
      </div>
      {isopen && childrens}
    </>
  );
};

export { MenuListAccordion };
