import { literal, object, number, string } from 'idonttrustlikethat';
import { keypointsDetails } from '../keypoint-odt/keypoint-odt';

const keypointsOdtComponent = literal("blocks.keypoints-gdp");
const keypointsOdtBlock = object({
    id: number,
    __component: keypointsOdtComponent,
    title : string,
    keypoints: keypointsDetails,
    description: string.nullable()
});

type KeypointsOdtBlock = typeof keypointsOdtBlock.T;
type KeypointsOdtComponent = typeof keypointsOdtComponent.T;

export { keypointsOdtBlock, keypointsOdtComponent };
export type { KeypointsOdtBlock, KeypointsOdtComponent };