import { FunctionComponent } from 'react';
import { Trans } from '@lingui/macro';

export const NotFound: FunctionComponent = () => (
  <div className="mt-32 text-center h-full">
    <p className="text-4xl mb-6">
      <Trans id="Ooops... page not found" component={null}>
        Ooops... page not found
      </Trans>
    </p>
    <p className="mb-2">
      <Trans id="This page is not available anymore" component={null}>
        This page is not available anymore
      </Trans>
    </p>
    <a href="/" className="underline">
      <Trans id="Go back to the homepage" component={null}>
        Go back to the homepage
      </Trans>
    </a>
  </div>
);

export default NotFound;
