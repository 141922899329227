import { FC } from 'react';
import './../menu-program-pages-list.scss';
import {
  MenuProgramPage,
  MenuProject,
} from '../../../../../../types/strapi/program-pages/menu-program-page/menu-program-page';
import { ProgramPagesRouteInfo } from '../../../../../../types/routing';
import { generateRedirectionUrl } from '../../utils/generate-redirection-url';
import { MenuListSubProject } from './menu-list-subproject';
import { iscurrentlySelectedContent } from '../../utils/is-currently-selected-content';
import { MenuListAccordion } from './menu-list-accordion';

interface MenuListProjectProps {
  menu: MenuProgramPage;
  project: MenuProject;
  routeInfo: ProgramPagesRouteInfo;
}

const MenuListProject: FC<MenuListProjectProps> = ({
  menu,
  project,
  routeInfo,
}) => {
  return (
    <div>
      {
        <MenuListAccordion
          className={`mppl-offset-project mppl-box${
            iscurrentlySelectedContent(routeInfo, project.projectSlug)
              ? ' mppl-box-selected'
              : ' mppl-box-not-selected'
          }`}
          href={generateRedirectionUrl(menu.programSlug, project.projectSlug)}
          parent={<span className="mppl-text">{project.projectMenuName}</span>}
          childrens={project.subProjects.map((subProject) => (
            <MenuListSubProject
              menu={menu}
              project={project}
              subProject={subProject}
              routeInfo={routeInfo}
            />
          ))}
        />
      }
    </div>
  );
};

export { MenuListProject };
